import React, { useState } from 'react';
import './App.css';
import Typewriter from 'typewriter-effect';
import { Box, Text } from '@chakra-ui/react';
import customTheme from './theme';
import { ChakraProvider } from '@chakra-ui/react';

function App() {
  const [showHome, setShowHome] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showTypewriter, setShowTypewriter] = useState(false);
  const [showBullets, setShowButton] = useState(false);
  const [name, setName] = useState('');
  const [showLogo, setShowLogo] = useState('');

  const [formData, setFormData] = useState({
    q1: '',
    q2: '',
    q3: '',
    q4: '',
    q5: '',
    q6: '',
    q7: '',
  });
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    postToGoogle();
    setShowHome(false);
    setShowForm(false);
    setShowTypewriter(true);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const postToGoogle = async () => {
    try {
      const response = await fetch(
        '',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            name: formData.q1,
            email: formData.q2,
            'What events do you work on?': formData.q3,
            'How long have you been with Informa?': formData.q4,
            'How long have you been in the Events Industry?': formData.q5,
            "Tell us the story of the best customer experience you've ever had.":
              formData.q6,
            "You've been handed a magic wand. It has the power to change 1 and only 1 thing about the current Informa Connect Experience.":
              formData.q7,
          }),
        }
      );

      if (response.ok) {
        setFormData({
          q1: '',
          q2: '',
          q3: '',
          q4: '',
          q5: '',
          q6: '',
          q7: '',
        });
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <ChakraProvider theme={customTheme}>
      <div style={{ background: 'black', height: '100vh' }}>
        <div className='background'>
          <div className='shape1'></div>
          <div className='shape2'></div>
          <div className='shape3'></div>
          <div className='shape4'></div>
          <div className='shape5'></div>
        </div>

        <div className='App'>
          {showHome || showForm ? (
            <Box
              display='flex'
              flexDirection='column'
              height={showHome ? '100vh' : '200px'}
              className={showHome ? 'logoWrapper' : 'logoWrapperForm'}
            >
              <img
                className={showHome ? 'logo' : 'logoForm'}
                src='/neptune.png'
              ></img>
              {showHome && (
                <Box p={5} textAlign="center">
                <Text fontSize="2xl" color="white" mb="2" mt="5">Thank you for your interest. Applications are now closed.</Text>
                <Text fontSize="xl" color="#36a9e1" >We will be in touch shortly.</Text>
                </Box>

                // <button
                //   onClick={() => {
                //     setShowHome(false);
                //     setShowForm(true);
                //   }}
                //   className='upsale-button'
                //   style={{
                //     maxWidth: 250,
                //     margin: '50px auto',
                //     padding: 40,
                //     fontWeight: 600,
                //   }}
                //   type='submit'
                // >
                //   <span>Are You Ready?</span>
                // </button>
              )}
            </Box>
          ) : (
            <></>
          )}

          <Box
            display={showForm ? 'flex' : 'none'}
            position='relative'
            flexDirection='column'
            w={{ base: '90%', md: '60%' }}
            margin='0px auto'
            overflow='scroll'
            className='detail-container'
          >
            <div className='strap-line'>
              <p className='line-1'>
                Becoming Neptune is looking for colleagues who want to lead
                Informa to outrageously amazing customer experiences. If your
                application is selected you will be flown to Las Vegas to
                participate in an intensive and immersive lab. You will emerge
                as a CX scientist... someone who lives, breathes, and executes
                incredible customer experiences:
              </p>
              <p className='line-2'>LIVE CX | LEARN CX | APPLY CX</p>
              <p className='line-3'>
                to your own event and inspire others to do the same
              </p>
            </div>

            <Box className='signup-form'>
              <h2>Apply to Becoming Neptune</h2>
              <form id='mG61Hd' onSubmit={handleSubmit}>
                <label required for='html'>
                  Your first name
                </label>
                <input
                  required
                  type='text'
                  name='q1'
                  value={formData.q1}
                  onChange={(event) => {
                    handleChange(event);
                    handleNameChange(event);
                  }}
                  placeholder='Type Here...'
                />
                <label required for='html'>
                  Your email address
                </label>
                <input
                  required
                  type='email'
                  name='q2'
                  value={formData.q2}
                  onChange={handleChange}
                  placeholder='Type Here...'
                />
                <label required for='html'>
                  What events do you work on?
                </label>
                <input
                  type='text'
                  required
                  name='q3'
                  value={formData.q3}
                  onChange={handleChange}
                  placeholder='Type Here...'
                />
                <label required for='html'>
                  How long have you been with Informa?
                </label>
                <textarea
                  rows='1'
                  required
                  type='text'
                  name='q4'
                  value={formData.q4}
                  onChange={handleChange}
                  placeholder='Type Here...'
                />
                <label required for='html'>
                  How long have you been in the Events Industry?
                </label>
                <textarea
                  rows='1'
                  required
                  type='text'
                  name='q5'
                  value={formData.q5}
                  onChange={handleChange}
                  placeholder='Type here...'
                />
                <label required for='html'>
                  Tell us the story of the best customer experience you've ever
                  had. Be specific and include all the details... who you were
                  with, what you were doing, where & when it was, how you felt
                  before, during, and after the experience.
                </label>
                <textarea
                  rows='5'
                  required
                  label='test'
                  type='text'
                  name='q6'
                  value={formData.q6}
                  onChange={handleChange}
                  placeholder='Type here..'
                />

                <label required for='html'>
                  You've been handed a magic wand. It has the power to change 1
                  and only 1 thing about the current Informa Connect Experience.
                  What do you change, and why? Be specific.
                </label>
                <textarea
                  rows='5'
                  required
                  label='test'
                  type='text'
                  name='q7'
                  value={formData.q7}
                  onChange={handleChange}
                  placeholder='Type here..'
                />

                <button className='upsale-button' type='submit'>
                  <span>Apply Now</span>
                </button>
              </form>
            </Box>
          </Box>

          {showTypewriter && !showForm && (
            <>
              <div className='typewriter'>
                <Typewriter
                  options={{
                    delay: 50,
                    loop: false,
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(
                        `Thank you ${name} for applying to Becoming Neptune. `
                      )
                      .pauseFor(1000)
                      .typeString(
                        "Your application has been received. Here's what happens next..."
                      )
                      .pauseFor(1500)
                      .callFunction(() => {
                        setShowButton(true);
                      })
                      .pauseFor(1500)
                      .callFunction(() => {
                        setShowLogo(true);
                      })
                      .start();
                  }}
                />
                <Box
                  className='typerwriter-bullets'
                  opacity={!showBullets ? 0 : 1}
                >
                  <ol>
                    <li>
                      The application deadline will close on 22nd November.
                    </li>
                    <li>
                      All applications will receive a decision notice on 27th
                      November.
                    </li>
                    <li>
                      The Becoming Neptune ConnectMe Experience will open on 4th
                      December.
                    </li>
                    <li>Becoming Neptune: Las Vegas 11th - 14th December.</li>
                    <li>
                      If you have any additional questions, please contact{' '}
                      <a href='mailto:helen.galliford@informa.com'>
                        Helen Galliford
                      </a>
                      .
                    </li>
                  </ol>
                </Box>
                <img
                  style={{
                    opacity: showLogo ? 1 : 0,
                    transition: 'opacity 2s ease-in-out',
                    margin: '30px auto 0px',
                    maxWidth: '430px',
                  }}
                  src='/neptune.png'
                ></img>
              </div>
            </>
          )}
        </div>
      </div>
    </ChakraProvider>
  );
}

export default App;
