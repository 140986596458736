// theme.js
import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  breakpoints: {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
  },
});

export default customTheme;